/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    houses: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.793 1a1 1 0 011.414 0l.647.646a.5.5 0 11-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 00.5.5.5.5 0 010 1A1.5 1.5 0 011 12.5V7.207l-.146.147a.5.5 0 01-.708-.708zm3 1a1 1 0 011.414 0L12 3.793V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v3.293l1.854 1.853a.5.5 0 01-.708.708L15 8.207V13.5a1.5 1.5 0 01-1.5 1.5h-8A1.5 1.5 0 014 13.5V8.207l-.146.147a.5.5 0 11-.708-.708zm.707.707L5 7.207V13.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5V7.207z"/>',
    },
});
